import { render, staticRenderFns } from "./Code.vue?vue&type=template&id=722dfc27&scoped=true"
import script from "./Code.vue?vue&type=script&lang=js"
export * from "./Code.vue?vue&type=script&lang=js"
import style0 from "./Code.vue?vue&type=style&index=0&id=722dfc27&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "722dfc27",
  null
  
)

export default component.exports