<template>
  <div class="container">
    <el-container class="wrap">
      <!-- 头部 -->
      <el-header >
        <Header />
      </el-header>
      <!-- 中间 -->
      <el-main class="mains">
        <img src="../assets/image/login_img.png" alt="" class="img m-b-40">
        <p class="font-56 text-bold m-b-70">
          <span class="c-title2">鸽伦布</span>
           - 多邻国1:1全真模考
        </p>
        <p class="c-60 font-18">全真1:1还原正式考试场景，让你提前感受考试流程全程自适应答题</p>
        <p class="c-60 font-18">难度自适应变化，准确分析您的优势和不足</p>
        <el-button class="loginbtn" @click="goLogin()">立即登录</el-button>
      </el-main>
    </el-container>

    <!-- 扫码登录弹窗 -->
    <Code :show="dialogVisible" v-if="dialogVisible" ref="codePanel"/>

  </div>
</template>

<script>
import Code from '@/components/Code.vue'
import Header from '@/components/Header.vue' 
export default {
  components: {
    Header,
    Code
  },
  data() {
    return {
      //弹窗
      dialogVisible: false,
      websiteInfo: {},
      timer: null, //定时器
    }
  },
  created() {
    // 判断有没有登录过
    if(localStorage.getItem("token")) {
      this.$router.push('/index')
    }else {
      this.getSetting()
    }
   },
  beforeDestroy() {
    if(this.timer) {
      clearInterval(this.timer)
    }
  },
  methods: {
    goLogin() {
      this.dialogVisible = true;
      //定时请求接口——判断扫码登录状态
      this.timer = setInterval(() => {
        this.getCheckLogin()
      }, 2000);
      
    },
    getCheckLogin() {
      this.$api.getCheckLogin({
        session_id: this.$refs.codePanel.session_id
      }).then(res => {
        if(res.code == 1) {
          this.$store.commit('setToken', res.data);
          localStorage.setItem("token",res.data);
          this.$router.push('/index')
        }
      })
    },
    getSetting() {
        this.$api.getSetting({}).then(res => {
            if (res.code == 1) {
                this.websiteInfo = res.data;
            }
        })
    },
  }
}
</script>

<style lang="scss" scoped>
  .container,.wrap {
    width: 100%;
    height: 100vh;
  }
  .el-header {
    height: 70px!important;
  }
 


.mains {
  height: calc(100% - 70px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // background: #F6F7FB;
  background-image: url('../assets/image/bg.png');
  background-position: 100% 100%;
  background-repeat: no-repeat;
  .img {
    width: 278px;
    height: 214px;
  }
  .loginbtn {
    width: 168px;
    height: 48px;
    border-radius: 2px;
    background: $color-title2;
    box-shadow: 0 8px 16.2px 0 #24008033;
    font-size: 16px;
    color: #fff;
    margin-top: 40px!important;
  }
}

</style>