<template>
   <div class="login">
      <el-dialog
        title="扫码登录"
        :visible.sync="show"
        width="593px"
        >
        <div class="dialogContent">
          <img :src="codeImg" alt="">
          <span class="font-20">请使用微信扫描二维码登录</span>
        </div>
      </el-dialog>
   </div>
</template>
<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    }
  },
  data() {
      return {
        codeImg: "",
        session_id: "",
      }
  },
  created() {
    this.getLoginCode()
  },
  methods: {
    getLoginCode() {
      this.$api.getLoginCode({}).then(res => {
        if(res.code == 1) {
          this.codeImg = res.data.url;
          this.session_id = res.data.session_id;
        }
      })
    },
  }
}
</script>
<style  lang="scss" scoped>
.login ::v-deep .el-dialog__header {
  display: flex;
  justify-content: center;
  font-weight: bold;
}
.dialogContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    width: 339px;
    height: 339px;
    background: #999;
    margin-bottom: 60px;
  }
}
</style>
